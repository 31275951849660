import {
  TextField,
  SimpleForm,
  TextInput,
  EditButton,
  SelectInput,
  required,
  useGetRecordId,
  useNotify,
  AutocompleteArrayInput,
  ReferenceInput,
  SimpleFormIterator,
  useTranslate,
  useRefresh,
  useSimpleFormIteratorItem,
  Confirm,
  IconButtonWithTooltip,
  ReferenceArrayInput,
  TopToolbar,
  CreateButton,
  AutocompleteInput,
  email,
  Identifier,
  useGetIdentity,
  BooleanInput,
  useRedirect,
  Create,
  Link,
  Button,
  ReferenceField,
  ListContextProvider,
  useDeleteMany,
  useUnselectAll,
} from 'react-admin';
import {
  List, Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import {
  Button as MuiButton,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel, IconButton,
  Switch,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GetListResult, useListContext, useRecordContext } from 'ra-core';
import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomerUserRelation, User } from '@x-guard/xgac-types/xgac';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateToolbarRight, CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import { locales } from '../../../lib/constants/localesOptions';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { httpClient } from '../../../utils/httpClient';
import { LIST_DEBOUNCE, XGAC_ALARM_MANAGER_BASEURL, XGAC_MAIN_API_URL } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { UserRoleChipField } from '../../../components/fields/UserRoleChipField';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import { ContactField } from '../../../components/fields/ContactField';
import { CustomBulkExportButton, FullExportButton } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { roleChoices } from '../../../lib/constants/roleOptions';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { ResetPasswordButton } from '../../../components/buttons/ResetPasswordButtons';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { UsernameInput } from '../../bhvk/components/inputs/UsernameInput';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { RaXgacRecord } from '../../../lib/RaXgacRecord';

const Grid = Grid2;
const apiUrl = XGAC_MAIN_API_URL;
const isAdmin = authProvider.isAdmin();

const editTransform = (data: Record<string, any>) => {

  delete data.specialPermissions;
  delete data.globalRoles;
  return {
    ...data,
  };

};

const simpleTransform = (data: Record<string, any>) => {

  data.properties = { ...data.properties, smsNumber: data.properties.phoneNumber };
  return editTransform(data);

};

const CreateTransform = (data: Record<string, any>) => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  if (isCustomerAdmin) {

    data.roles = ['customer_admin'];
    if (data.mapViewerRole) {

      data.roles.push('map_viewer');

    }

  }
  delete data.mapViewerRole;

  if (!data.properties?.smsNumber) {

    data.properties = { ...data.properties, smsNumber: data.properties.phoneNumber };

  }
  if (!data.roles) {

    data.roles = ['customer_admin'];

  }

  const url = window.location.href;
  if (url.includes('/users/create')) {

    return { ...data, globalRoles: ['admin'] };

  } return data;

};

const RelationRemoveButton = () => {

  const { remove } = useSimpleFormIteratorItem();
  const [dialogOpen, setDialogOpen] = useState(false);
  const translate = useTranslate();

  const handleDelete = () => {

    remove();
    setDialogOpen(false);

  };

  return (
    <>
      <IconButtonWithTooltip
        label="ra.action.remove"
        size="small"
        onClick={() => setDialogOpen(true)}
        color="warning"
      >
        <CloseIcon fontSize="small" />
      </IconButtonWithTooltip>
      <Confirm
        content={translate('ra.message.delete_content')}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDelete}
        title={translate('ra.action.delete')}
        isOpen={dialogOpen}
      />
    </>
  );

};

const NameField = () => {

  const record = useRecordContext();
  const loggedInUser = useGetIdentity().data?.id;
  const translate = useTranslate();

  return <div style={{ display: 'flex', flexDirection: 'row' }}>
    <TextFieldNullable source="username" privacy/>
    {record?.id === loggedInUser && (
      <Chip clickable={false} label={translate('general.text.current_user')} size="small" color="primary"/>
    )}
  </div>;

};

const UserBulkActionButtons = () => {

  const DeleteUsersButton = () => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usersWithMultipleCustomers, setUsersWithMultipleCustomers] = useState<RaXgacRecord<CustomerUserRelation>[]>([]);
    const { selectedIds } = useListContext();
    const [deleteMany] = useDeleteMany('users');
    const translate = useTranslate();
    const unselectAll = useUnselectAll('users/without-app');
    const refresh = useRefresh();

    useEffect(() => {

      if (!dialogOpen) {

        setUsersWithMultipleCustomers([]);
        return;

      }
      const fetchUsers = async () => {

        setLoading(true);
        const relations: GetListResult<RaXgacRecord<CustomerUserRelation>> = await xgacDataProvider.getList('customer-user-relations', {
          filter: { 'user._id': selectedIds, 'customer._id': undefined },
          pagination: { page: 1, perPage: -1 },
          sort: { field: 'user._id', order: 'ASC' },
        });

        // find all users that are in the relations list multiple times
        const generatedUsersWithMultipleCustomers = relations.data.filter((relation) => {

          return relations.data.filter((r) => r.user._id === relation.user._id).length > 1;

        }).filter((relation, index, self) => self.findIndex((r) => r.user._id === relation.user._id) === index);

        setUsersWithMultipleCustomers(generatedUsersWithMultipleCustomers);
        setLoading(false);

      };
      fetchUsers();

    }, [dialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteUsers = () => {

      deleteMany('users', { ids: selectedIds }).then(() => {

        setDialogOpen(false);
        unselectAll();
        refresh();

      });

    };
    return (
      <>
        <Button onClick={() => setDialogOpen(true)} color="warning" startIcon={<DeleteIcon/>} label={'ra.action.delete'}/>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'sm'} fullWidth>
          <DialogTitle className="flex-in-between">
            <span>{translate('resources.users.text.delete_users')}</span>
            <IconButton onClick={() => setDialogOpen(false)}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>

            {loading || usersWithMultipleCustomers.length > 0 ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
              <>
                {usersWithMultipleCustomers.length > 0 && (
                  <span>{translate('resources.users.text.users_with.multiple_connections', { smart_count: usersWithMultipleCustomers.length })}</span>
                )}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <ListContextProvider value={{
                  data: usersWithMultipleCustomers,
                  total: usersWithMultipleCustomers.length,
                  isLoading: loading,
                }}>
                  <Datagrid data={usersWithMultipleCustomers} rowClick={false} resource={'customer-user-relations'}>
                    <ReferenceField source="user._id" reference="users"/>
                  </Datagrid>
                </ListContextProvider>
              </>
            ) : (
              <span>{translate('resources.users.text.delete_users_warning', { smart_count: selectedIds.length })}</span>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} label="ra.action.cancel"/>
            <Button onClick={deleteUsers} color="warning" disabled={loading} label="ra.action.delete"/>
          </DialogActions>
        </Dialog>
      </>
    );

  };
  return (
    <>
      <IfCanAccess action="export">
        <CustomBulkExportButton/>
      </IfCanAccess>
      <IfCanAccess action="delete">
        <DeleteUsersButton/>
      </IfCanAccess>

    </>
  );

};

export const UserList = () => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  const url = window.location.href;
  let filter = {};
  if (!url.includes('without-app')) {

    filter = { globalRoles: ['admin'], 'customer._id': undefined };

  }
  return (
    <>
      <PageExplanation text="resources.users.text.page_info" />
      <List title={'menu.items.users'}
        filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={false} filter={filter}
        actions={<TopToolbar><CreateButton/><FullExportButton resource={ isCustomerAdmin ? 'users/without-app' : 'users'}/></TopToolbar>}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <>
          <FilterResetter/>
          <Datagrid
            rowClick="toggleSelection"
            bulkActionButtons={
              <UserBulkActionButtons/>}
          >
            <NameField/>
            <TextField source="name" label="general.fields.name"/>
            <ContactField label="general.text.contact_info"/>
            <UserRoleChipField />
            <DateFieldNullable source="lastActiveAt" label="resources.users.fields.lastActiveAt" showTime />
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <IfCanAccess action={'edit'} resource={'users'}>
              <EditButton/>
            </IfCanAccess>
            <HasRoles anyOf={['developer_admin']}>
              <AuditlogButton/>
            </HasRoles>
          </Datagrid>
        </>
      </List>
    </>
  );

};

const SimpleUserEditFormFields = () => {

  const record: User & { id: Identifier } | undefined = useRecordContext();
  const translate = useTranslate();

  if (!record) {

    return null;

  }

  return (
    <>
      <h4 style={{ marginBottom: '10px' }}>"{record.name}" {translate('general.text.settings')}</h4>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.assets.text.properties')}
      </Typography>
      <Grid container spacing={3}>
        <Grid md={6}>
          <TextInput
            source="name"
            label="general.fields.name"
            fullWidth
            validate={required()}/>
          <TextInput
            source="properties.email"
            label="resources.assets.fields.email"
            fullWidth
            validate={email()}/>
        </Grid>
        <Grid md={6}>
          <TextInput source="username" disabled fullWidth/>
          <PhoneNumberInput source="properties.phoneNumber" fullWidth colorVariant="normal"/>
        </Grid>
      </Grid>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('general.text.actions')}
      </Typography>
      <ResetPasswordButton user={record}/>
    </>
  );

};

export const UserEdit = () => {

  const notify = useNotify();
  const refresh = useRefresh();
  const recordId = useGetRecordId();
  const translate = useTranslate();

  const isCustomerAdmin = authProvider.isCustomerAdmin();

  const ResetButton = (props: { resetToBase: boolean }) => {

    const [dialogOpen, setDialogOpen] = useState(false);

    const sendPasswordReset = async () => {

      const path = props.resetToBase ? 'to-base-password' : 'reset-password';

      const passwordResetRequest = await httpClient(`${apiUrl}/users/${path}/${recordId}`);
      const passwordRequestBody = JSON.parse(passwordResetRequest.body);
      if (passwordRequestBody.statusCode < 400) {

        notify(`resources.users.text.password_reset${props.resetToBase ? '_to_base' : ''}_sent`, { type: 'success' });

      } else {

        notify(`resources.users.text.password_reset${props.resetToBase ? '_to_base' : ''}_failed`, { type: 'warning' });

      }
      setDialogOpen(false);

    };

    const handleClickOpen = () => {

      setDialogOpen(true);

    };
    const handleClose = () => {

      setDialogOpen(false);

    };

    const record = useRecordContext();

    if (!record) {

      return null;

    }

    return (<><Card sx={{ marginBottom: '10px' }}>
      <CardContent>
        <MuiButton
          onClick={handleClickOpen}
          disabled={!props.resetToBase && (!record.properties.email || record.properties.email?.length === 0)}
        >
          {translate(props.resetToBase ? 'resources.users.text.password_reset_to_base' : 'resources.users.text.password_reset_button')}
        </MuiButton>
      </CardContent>
    </Card>
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflowY: 'visible',
        },
      }}
    >
      <DialogTitle>
        {translate(props.resetToBase ? 'resources.users.text.password_reset_to_base' : 'resources.users.text.password_reset_button')}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <DialogContentText>
          {translate(props.resetToBase ? 'resources.users.text.password_reset_button_description'
            : 'resources.users.text.password_reset_to_base_description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={handleClose} autoFocus>
          <>{translate('ra.action.cancel')}</>
        </MuiButton>
        <MuiButton onClick={sendPasswordReset}>
          <>
            {translate(props.resetToBase ? 'resources.users.text.password_reset_to_base_confirm' : 'resources.users.text.password_reset_mail_confirm')}
          </>
        </MuiButton>
      </DialogActions>
    </Dialog></>);

  };

  const OnSuccess = () => {

    notify('resources.users.text.update_success', { type: 'success' });
    refresh();

  };

  const onError = (error: any) => {

    if (error.message === 'Conflict') {

      notify('resources.users.text.username_already_exists', { type: 'warning' });

    } else {

      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });

    }

  };

  if (isCustomerAdmin) {

    return (
      <EditWithSuccess title="menu.items.users" actions={false} mutationOptions={{ onSuccess: OnSuccess, onError }} transform={simpleTransform} >
        <SimpleForm toolbar={<CustomToolbar customRedirect={'users/without-app'} narrow/>} sx={{ maxWidth: '1000px' }}>
          <SimpleUserEditFormFields/>
        </SimpleForm>
      </EditWithSuccess>
    );

  }

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <EditWithSuccess title="menu.items.users" actions={false} mutationOptions={{ onSuccess: OnSuccess, onError }} transform={editTransform} >
        <SimpleForm toolbar={<CustomToolbar customRedirect={'users/without-app'}/>}>
          <Typography variant="h6" gutterBottom>
            {translate('general.fields.name')}
          </Typography>
          <Grid container spacing={2} width='100%'>
            <Grid xs={8}>
              <TextInput source="username" sx={{ display: 'block' }}/>
              <TextInput source="name" sx={{ display: 'block' }} validate={required()} label="general.fields.name"/>
            </Grid>
            <Grid xs={4}>
              <ResetButton resetToBase={false}/>
              <ResetButton resetToBase={true}/>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.text.properties')}
          </Typography>
          <PhoneNumberInput source="properties.phoneNumber" label="resources.assets.fields.phoneNumber"/>
          <PhoneNumberInput source="properties.smsNumber" label="resources.assets.fields.smsNumber"/>
          <TextInput source="properties.email" label="resources.assets.fields.email"/>
          <SelectInput source="properties.locale" choices={locales} defaultValue='nl' validate={required()} label="resources.customers.fields.locale" />
          {isAdmin && (
            <><Typography variant="h6" gutterBottom>
              {translate('resources.users.text.customer_user_relations')}
            </Typography>
            <ReferenceManyInput reference='customer-user-relations'
              target='user._id'
              label=""
            >
              <SimpleFormIterator disableReordering fullWidth removeButton={<RelationRemoveButton/>}>
                <ReferenceInput name="customer" source='customer._id' reference="customers" sort={{ field: 'name', order: 'ASC' }}
                  label="resources.alarms.fields.customer._id">
                  <AutocompleteInput validate={required()} />
                </ReferenceInput>
                <AutocompleteArrayInput source="roles" choices={roleChoices} validate={required()} label="resources.users.text.roles_title"/>
                <ReferenceArrayInput source="assetGroupRestrictions" reference="asset-groups">
                  <AutocompleteArrayInput label="resources.users.fields.group_restrictions"/>
                </ReferenceArrayInput>
              </SimpleFormIterator>
            </ReferenceManyInput></>
          )}
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const UserCreate = () => {

  const roles = authProvider.getRoles();
  const isCustomerAdmin = roles?.includes('customer_admin');
  const isMapViewer = roles?.includes('map_viewer');

  const notify = useNotify();
  const redirect = useRedirect();

  const onError = (error: any) => {

    if (error.message === 'Conflict') {

      notify('resources.users.text.username_already_exists', { type: 'warning' });

    } else {

      notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });

    }

  };

  const onSuccess = (data: any) => {

    notify('resources.users.text.create_success', { type: 'success' });
    redirect('edit', '/users', data.id);

  };
  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <Create title="menu.items.users" transform={CreateTransform} mutationOptions={{ onError, onSuccess }}>
        <SimpleForm toolbar={<CreateToolbarRight narrow/>}>
          <Typography variant="h6" gutterBottom>
            {translate('general.fields.name')}
          </Typography>
          <UsernameInput fromEmail={false} disableFullWidth color="primary" sx={{
            marginBottom: '20px',
          }}/>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.assets.text.properties')}
          </Typography>
          <PhoneNumberInput source="properties.phoneNumber"
            label="resources.assets.fields.phoneNumber"
          />
          {!isCustomerAdmin && (
            <PhoneNumberInput source="properties.smsNumber"
              label="resources.assets.fields.smsNumber"
            />
          )}
          <TextInput source="properties.email" label="resources.assets.fields.email"/>
          <SelectInput source="properties.locale" choices={locales} defaultValue='nl' validate={required()} label="resources.customers.fields.locale" />
          {isAdmin && !window.location.href.includes('/users/create') && (
            <AutocompleteArrayInput source="roles"
              label="resources.users.text.roles_title"
              choices={roleChoices}
              validate={required()}
              defaultValue={['customer_admin']}
            />
          )}
          {isCustomerAdmin && (
            <>
              <Typography variant="h6" gutterBottom>
                {translate('menu.items.access')}
              </Typography>
              <BooleanInput source="mapViewerRole" label={'resources.users.fields.map_viewer'} disabled={!isMapViewer} helperText={false}/>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel disabled control={<Switch checked={true} />} label={translate('resources.users.text.has_access_to_dashboard')} />
                <Chip size="small" clickable={false} label={translate('general.text.this_dashboard')} sx={{ marginTop: 'auto', marginBottom: 'auto' }} />
              </div>
              <FormControlLabel
                disabled
                control={<Switch checked={true} />}
                label={<Link to={XGAC_ALARM_MANAGER_BASEURL}>
                  {translate('resources.users.text.has_access_to_am')}
                  <OpenInNewIcon sx={{ fontSize: '0.875rem' }}/>
                </Link>} />
            </>
          )}
        </SimpleForm>
      </Create>
    </>
  );

};
