/* eslint-disable @typescript-eslint/no-unused-vars */
// in src/Menu.js
import * as React from 'react';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { useLogout, useTranslate } from 'react-admin';
import {
  useMediaQuery, Theme, Card, CardContent,
} from '@mui/material';
import { useEffect } from 'react';
import { StyledButton } from './buttons/StyledButton';
import { httpClient } from '../../utils/httpClient';
import { getCurrentCustomer } from '../../lib/currentCustomer';

export const CustomMenu: any = (props: any) => {

  const translate = useTranslate();

  const logout = useLogout();
  const currentCustomer = getCurrentCustomer();
  const [lastCurrentCustomer, setLastCurrentCustomer] = React.useState<string | null>(null);
  const [dealer, setDealer] = React.useState<any>(null);

  useEffect(() => {

    if (!lastCurrentCustomer) {

      setLastCurrentCustomer(currentCustomer?.value || null);

    }
    if (lastCurrentCustomer === currentCustomer?.value) {

      return;

    }
    setLastCurrentCustomer(currentCustomer?.value || null);

    const currentDomain = window.location.hostname;
    const splicedDomain = currentDomain.split('.').splice(-2).join('.');
    httpClient(`https://${splicedDomain}/?get_info=${currentCustomer?.value}`, { headers: { Authorization: '' } }).then((response: any) => {

      if (response && response.json?.name) {

        setDealer(response.json);

      } else {

        setDealer(null);

      }

    }).catch(() => {

      setDealer(null);

    });

  }, [currentCustomer, lastCurrentCustomer]);

  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  if (!isLargeEnough) return null;

  return <MultiLevelMenu
    initialOpen={true}
    sx={{
      paddingBottom: '2em',
      height: '100%',
    }}
    {...props}
  >
    <MultiLevelMenu.Item name="dashboard" to={'/'} label="menu.items.dashboard" />

    <div className={'category'}></div>
    <MultiLevelMenu.Item name="demo" to={'/maak-alarm'} label="menu.items.create_alarm" />
    <MultiLevelMenu.Item name="alarms" to={'/alarms?page=1'} label="menu.items.alarms" />

    <div className={'category'}></div>
    <MultiLevelMenu.Item name={'assets'} to={'/assets'} label={'resources.users.text.title'}/>

    <MultiLevelMenu.Item name={'asset-groups'} to={'/asset-groups'} label={'menu.items.groups'}/>

    <MultiLevelMenu.Item name={'buttons'} to={'/buttons'} label={'resources.devices.text.buttons'}/>
    <MultiLevelMenu.Item name="report-triggers" to="/report-triggers" label="resources.reports.title"/>
    <MultiLevelMenu.Item name="overlays" to="/overlays" label="resources.overlays.text.title"/>

    <div className={'category'}></div>
    <>
      <MultiLevelMenu.Item name={'order'} to={'/bestellen'} label={'general.text.order'}/>
      <MultiLevelMenu.Item name={'orders'} to={'/bestellingen'} label={'general.text.orders'}/>
    </>

    <div className={'category'}></div>
    <MultiLevelMenu.Item name={'help'} to={'/help'} label={'general.text.help'}/>
    <MultiLevelMenu.Item name={'install'} to={'/help/de-app/hoe-installeer-je-de-bhv-knop-nl-app/'} label={'general.text.install_app'}/>
    {dealer && (
      <Card style={{
        backgroundColor: dealer.color,
        position: 'fixed',
        width: '160px',
        bottom: '55px',
        left: '20px',
      }}>
        <CardContent style={{ paddingBottom: '5px !important' }}>
          <div style={{
            color: dealer.textcolor,
            textAlign: 'center',
            marginTop: '10px',
            marginBottom: '5px',
            fontFamily: '"Barlow Condensed", sans-serif',
            fontSize: '12px',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}>{translate('menu.text.brought_by')}</div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src={dealer.logocolor} alt={dealer.name} style={{ width: '90px' }}/>
          </div>
        </CardContent>
      </Card>
    )}
    <StyledButton
      onClick={logout}
      label="ra.auth.logout"
      color={'primary'}
      style={{
        position: 'fixed',
        bottom: '20px',
        width: '160px',
        left: '20px',
      }}
    />
  </MultiLevelMenu>;

};
