import { useInput, useTranslate } from 'react-admin';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { SyntheticEvent } from 'react';
import * as React from 'react';

export const MultipleChipInput = (props: {
  source: string;
  label: string;
  validate?: any;
  invalidValues?: string[];
  helperText?: string;
  shouldUnregister?: boolean;
}) => {

  const { field, fieldState } = useInput({ source: props.source, validate: props.validate, shouldUnregister: props.shouldUnregister });
  const translate = useTranslate();

  const handleChange = (event: SyntheticEvent, value: string[]) => {

    event.preventDefault();
    field.onChange(value);

  };

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      fullWidth={true}
      handleHomeEndKeys
      defaultValue={field.value || []}
      freeSolo
      autoSelect
      onChange={handleChange}
      renderTags={(renderValue: readonly string[], getTagProps) => renderValue.map((option: string, index: number) => (
        <Chip
          variant="filled"
          size="small"
          label={option}
          color={props.invalidValues?.includes(option) ? 'error' : 'default'}
          {...getTagProps({ index })} />
      ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={true}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || (props.helperText ? translate(props.helperText) : undefined)}
          label={translate(props.label)}
        />
      )}
    />);

};
