import { AccountCircle } from '@mui/icons-material';
import { Card, CardContent, Box } from '@mui/material';
import { Button } from 'react-admin';

export type CounterCardProps = {
  count: number;
  total: number;
  label: string;
  warning?: boolean;
  icon?: typeof AccountCircle;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
  showButton?: {
    label: string;
    onClick: () => void;
  };
};

export const CounterCard = (props: CounterCardProps) => {

  const {
    count,
    total,
    label,
    warning,
    icon = AccountCircle,
    actionButton,
    showButton,
  } = props;

  const Icon = icon;

  return <Card color={'white'} style={{ height: '100%' }} className={warning ? 'warning' : ''} elevation={2}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <span style={{
            fontSize: '60px', lineHeight: '60px', fontWeight: 400,
          }}>{count}</span>
          <span style={{
            fontSize: '40px', fontWeight: 100,
          }}>/</span>
          <span style={{
            fontSize: '30px', fontWeight: 100,
          }}>{total}</span>
        </Box>
        <Box>
          <Icon fontSize={'large'} />
        </Box>
      </Box>
      <Box>
        <strong>{label}</strong>
      </Box>
      {showButton && <Box>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          style={{ width: '100%', marginTop: '1em' }}
          onClick={showButton.onClick}
          label={showButton.label} />
      </Box>}
      {actionButton && <Box>
        <Button
          variant={'contained'}
          color={'warning'}
          size={'small'}
          style={{ width: '100%', marginTop: '1em' }}
          onClick={actionButton.onClick}
          label={actionButton.label} />
      </Box>}
    </CardContent>
  </Card>;

};
