import {
  AlarmSeverity, AlarmType, Asset, Device,
} from '@x-guard/xgac-types/xgac';
import * as React from 'react';
import { useEffect } from 'react';
import { useGetIdentity, useNotify, useTranslate } from 'react-admin';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Tooltip,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StyledButton } from '../buttons/StyledButton';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import { httpClient } from '../../../utils/httpClient';
import { ATS_API_URL } from '../../../config';
import { atsDataProvider } from '../../../dataProviders/atsDataProvider';

const atsUrl = ATS_API_URL;

export const TestButtonButton = (props: { button: Asset & { device: Device } }) => {

  const [ownAsset, setOwnAsset] = React.useState<Asset | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const [templateMeta, setTemplateMeta] = React.useState<any>(
    {
      afterCallBehaviour: 'redirect_call_helper',
      forceLocale: 'nl',
      // eslint-disable-next-line max-len
      customMessage: "Hallo, BHV-knop ingedrukt. %{message} op locatie %{asset} op %{address}. Toets 5 om de melding te accepteren en te spreken met de opgeroepen BHV'ers. Hallo, BHV-knop ingedrukt. %{message} op locatie %{asset} op %{address}. Toets 5 om de melding te accepteren en te spreken met de opgeroepen BHV'ers.",
    },
  );
  const userId = useGetIdentity().data?.id;
  const translate = useTranslate();
  const notify = useNotify();
  const currentCustomer = getCurrentCustomer()?.value;

  useEffect(() => {

    atsDataProvider.getOne('process-templates', { id: '334df770-17c7-4dbb-8f6b-eebb5f75e270' }).then((response) => {

      if (response.data && response.data.scheduleTasks) {

        const metaData = response.data.scheduleTasks[0].metaData;
        if (metaData) {

          setTemplateMeta(metaData);

        }

      }

    });

  }, []);

  useEffect(() => {

    if (userId) {

      xgacDataProvider.getList(
        'all-assets',
        {
          filter: { 'user._id': userId },
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'name', order: 'ASC' },
        },
      ).then((response) => {

        if (response.data.length > 0) {

          const asset = response.data[0] as unknown as Asset & { device: Device };
          if (asset.properties.phoneNumber) {

            setOwnAsset(response.data[0]);
            setButtonDisabled(false);

          }

        }

      });

    }

  }, [userId]);

  const sendAlarmToATS = async () => {

    const data = {
      ifScript: null,
      metaScript: {
        name: 'getAssets',
        meta: {
          filter: {},
          xgacFilter: {
            request: {},
            result: {
              assetId: '==%alarmAssetId%',
            },
            max: 1,
          },
        },
      },
      taskTemplate: 'ca870f51-c1f5-4234-a311-58bcbb41424f',
      delay: 0,
      alarmData: {
        document: {
          _id: uuidv4(),
          customer: {
            _id: currentCustomer,
            _ref: 'Customer',
          },
          name: 'BHV-Knop ingedrukt',
          type: AlarmType.Assistance,
          asset: {
            _id: ownAsset?._id,
            _ref: 'Asset',
            name: props.button.name,
          },
          ack: {
            needed: true,
            value: false,
          },
          position: props.button.position || null,
          severity: AlarmSeverity.Red,
          procedure: '',
        },
      },
      metaData: templateMeta,
    };

    await httpClient(`${atsUrl}/v2/manual-task`, { method: 'POST' }, data);
    notify('resources.buttons.text.test_sent');
    setDialogOpen(false);

  };

  if (props.button.device.type !== 'lora') return null;

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{translate('resources.buttons.text.test_button_title')}</DialogTitle>
        <DialogContent>
          {translate('resources.buttons.text.test_button_content')}
        </DialogContent>
        <DialogActions>
          <StyledButton label="ra.action.cancel" color="warning" onClick={() => setDialogOpen(false)} autoFocus/>
          <StyledButton label="general.text.send" color="primary" onClick={sendAlarmToATS}/>
        </DialogActions>
      </Dialog>
      {buttonDisabled ? (
        <>
          <Tooltip arrow placement="left-start" title={translate('resources.buttons.text.test_disabled')}>
            <div>
              <StyledButton label="general.text.test" color="primary" disabled/>
            </div>
          </Tooltip>
        </>
      )
        : <StyledButton label="general.text.test" color="primary" onClick={() => setDialogOpen(true)}/>
      }
    </>
  );

};
